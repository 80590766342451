import IconSVG from '../../styles/svg-icons';
import cn from 'classnames';
import { OnHoverTooltip } from './OnHoverTooltip';
import { ContextMenuItem } from '../controls/ContextMenuItem';
import { ClassIndicators } from '../../types/amr-pipeline/models/ClassIndicators';
import { constants } from '../../constants';
import { useContext, useState } from 'react';
import { Tooltip, TooltipPlacement, TooltipTriggerType } from './Tooltip';
import { logger } from '../../logging/logger';
import { ActionBlocker } from '../access/ActionBlocker';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';

type Props = {
    identifier?: string;
    identifiers?: ClassIndicators;
    text?: string;
    className?: string;
    disabled?: boolean;
    textLink?: boolean;
    placeholderIfEmpty?: boolean;
    secondaryPlaceholderColor?: boolean;
};

type BloombergFunction = 'DES' | 'DES%202' | 'CLC' | 'CLP' | 'CFT' | 'TRIG' | 'MV';

const BLOOMBERG_FUNCTIONS: {
    name: BloombergFunction;
    text: string;
}[] = [
        {
            name: 'DES',
            text: 'DES - Security Description',
        },
        {
            name: 'DES%202',
            text: 'DES 2 - Collateral Description',
        },
        {
            name: 'CLC',
            text: 'CLC - Collateral Composition',
        },
        {
            name: 'CLP',
            text: 'CLP - Collateral Performance',
        },
        {
            name: 'CFT',
            text: 'CFT - Cash Flow Table',
        },
        {
            name: 'TRIG',
            text: 'TRIG - Trigger Information',
        },
        {
            name: 'MV',
            text: 'MV - Market Value',
        },
    ];

export function BloombergLink({
    identifiers,
    identifier,
    className,
    placeholderIfEmpty,
    secondaryPlaceholderColor,
    textLink = false,
}: Props) {
    const blockedFromOutside = useContext(BlockedFeatureContext);
    const [visible, setVisible] = useState<boolean>(false);

    const identifierForLink = identifier ?? getBloombergIdentifier(identifiers);

    if (!identifierForLink) {
        return placeholderIfEmpty ? (
            <span className={cn({ 'action-empty-placeholder': secondaryPlaceholderColor })}>
                {constants.emptyPlaceholder}
            </span>
        ) : null;
    }

    function getBloombergIdentifier(identifiers?: ClassIndicators) {
        if (!identifiers) {
            return undefined;
        }

        const { ticker144A, tickerAccdInvCertif, tickerRegS } = identifiers;

        return ticker144A ?? tickerAccdInvCertif ?? tickerRegS;
    }

    const handleVisibilityChange = (visible: boolean) => {
        setVisible(visible);
    };

    const generateBloombergLink = (functionName: BloombergFunction) => {
        return `https://blinks.bloomberg.com/screens/${identifierForLink}%20Mtge%20${functionName}  `;
    };

    const getMenuItems = () => {
        return (
            <>
                {BLOOMBERG_FUNCTIONS.map(item => {
                    const link = generateBloombergLink(item.name);

                    if (!link) {
                        return null;
                    }

                    return (
                        <ContextMenuItem
                            onClick={() => {
                                logger.trace('User clicked the Bloomberg link');
                                window.open(link, '_blank');
                            }}
                            key={item.name}
                        >
                            {item.text}
                            <IconSVG name="external-link" width={16} height={16} />
                        </ContextMenuItem>
                    );
                })}
            </>
        );
    };

    const renderBlockedView = () => (
        <div className="bloomberg-wrapper">
            <button className={cn('btn-link btn-link-bloomberg', className)} disabled>
                <IconSVG name="bloomberg" width={16} height={16} />
                {textLink && <span className="align-middle">Bloomberg</span>}
            </button>
        </div>
    );

    const renderTooltip = () => (
        <Tooltip
            className="bloomberg-wrapper"
            overlayClassName={cn('kp-popover', className)}
            placement={TooltipPlacement.BottomLeft}
            onVisibleChange={handleVisibilityChange}
            trigger={TooltipTriggerType.Click}
            visible={visible}
            overlay={() => (
                <div className="options-list options-list-bloomberg">
                    <ul>{getMenuItems()}</ul>
                </div>
            )}
        >
            <span onMouseEnter={e => e.stopPropagation()}>
                <OnHoverTooltip overlay="Launch Bloomberg to view additional data" disabled={visible}>
                    <button className={cn('btn-link btn-link-bloomberg', className)}>
                        <IconSVG name="bloomberg" width={16} height={16} />
                        {textLink && <span className="align-middle">Bloomberg</span>}
                    </button>
                </OnHoverTooltip>
            </span>
        </Tooltip>
    );

    return (
        <ActionBlocker feature={SubscriptionFeature.IntegrationsBloomberg}>
            {blocked => (blocked || blockedFromOutside ? renderBlockedView() : renderTooltip())}
        </ActionBlocker>
    );
}
