import React from 'react';
import classNames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { securityDetailsActions, controlPanelActions } from '../../actions';

export const TickerButton = ({ security, fetchAgencyRatings = false, onClick }) => {
    const dispatch = useDispatch();
    const selectedSecurity = useSelector(store => store.securityDetails.security);
    const id = security.id ?? security.securityId;
    const selectedId = selectedSecurity?.id ?? selectedSecurity?.securityId;
    const isActive = selectedSecurity && id === selectedId;

    const handleClick = e => {
        if (isActive) {
            onClick?.(e);
            dispatch(securityDetailsActions.securityDetailsReset());
        } else {
            onClick?.(e, security);
            dispatch(controlPanelActions.hide());
            dispatch(securityDetailsActions.securityDetailsInitRequest(security, fetchAgencyRatings));
        }

        e.stopPropagation();
    }


    return (
        <button
            className={classNames('pseudo-link', { 'is-active': isActive })}
            onClick={handleClick}
            >
            {security.ticker}
        </button>
    );
};

TickerButton.defaultProps = {
    onClick: null
}
