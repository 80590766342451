import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { OriginatingTransaction } from '../../../../types/amr-pipeline/models/OriginatingTransaction';
import { useEffect, useState } from 'react';
import { RequestState } from '../../../../constants/request-state';
import { amrPipelineService } from '../../../../services/amr-pipeline.service';
import { isRequestNone } from '../../../../utils';
import { DealSecurityStatistics } from '../../../../types/security/DealSecurityStatistics';
import { bwicDealsService } from '../../../../services';
import { errorActions } from '../../../../actions';
import { compareClassName, compareDates, compareEmpty } from '../../../../utils/compare.utils';

export function useLatestDealTransactions(companyReferenceName?: string) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [transactions, setTransactions] = useState<OriginatingTransaction[]>([]);

    useEffect(() => {
        if (companyReferenceName && isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const transactions = await amrPipelineService.getLatestTransactions(companyReferenceName);
                    setTransactions(transactions)
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [requestState, companyReferenceName])

    return { requestState, transactions };
}

export function useDealSecurityStatistics(dealTickers: string[], disabled: boolean = false) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [statistics, setStatistics] = useState<DealSecurityStatistics[]>([]);

    const resetFlag = JSON.stringify(dealTickers.sort());

    useEffect(() => {
        setStatistics([]);
        setRequestState(RequestState.none);
    }, [resetFlag]);

    useEffect(() => {
        if (!disabled && dealTickers.length && isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const statistics: DealSecurityStatistics[] = await bwicDealsService.getDealSecurityStatistics(dealTickers);
                    const sorted = statistics.map(s => ({
                        ...s,
                        classes: s.classes?.sort((a, b) =>
                            compareEmpty(a.closingDate, b.closingDate) || // put classes without closing date down
                            compareDates(b.closingDate, a.closingDate) ||
                            compareClassName(a.name, b.name)
                        )
                    }))
                    setStatistics(sorted)
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [dealTickers, disabled, requestState])

    return { requestState, statistics };
}

export function useDownloadDealSecurityDocuments() {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = useState(RequestState.none);


    const downloadCallback = async (dealTicker: string, dealName: string) => {
        try {
            setRequestState(RequestState.request);
            const document = await bwicDealsService.downloadDealSecurityDocuments(dealTicker, dealName ?? 'docs');
            setRequestState(RequestState.success);
            saveAs(document.blob, document.name);
        } catch (e) {
            setRequestState(RequestState.failure);
            dispatch(errorActions.unexpectedError(e));
        }
    }

    return { downloadCallback, requestState };
}
