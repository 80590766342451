import { FormikContextType, getIn, useField, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addressActions } from '../../../actions/address.actions';
import { CloManagerSave } from '../../../types/clo-managers/CloManagerSave';
import { AppState } from '../../../types/state/AppState';
import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { AutoCompleteInput } from '../../controls';
import { TextField } from '../../common/form-fields/TextField';
import { SelectField } from '../../common/form-fields/SelectField';
import { InputField } from '../../common/form-fields/InputField';
import { constants } from '../../../constants';

export const ContactInformation = () => {
    const { setFieldValue } = useFormikContext();
    const [countryField] = useField('countryId');
    const [stateField] = useField('stateId');

    const dispatch = useDispatch();
    const { countries, states, cities } = useSelector((state: AppState) => state.address);
    const usaCountryId =
        useMemo(
            () => countries.data.find(x => x.twoCharsCode === constants.USCountryCode)?.id,
            [countries]
        );

    const selectedCountryId = parseInt(countryField.value);
    const isUSASelected = selectedCountryId === usaCountryId;

    useEffect(() => {
        if (!countries.isLoaded) {
            dispatch(addressActions.loadCountries());
        }
    }, [dispatch, countries.isLoaded]);

    useEffect(() => {
        if (isUSASelected) {
            dispatch(addressActions.loadStates(selectedCountryId));
        }
    }, [dispatch, selectedCountryId, isUSASelected]);

    useEffect(() => {
        if (selectedCountryId) {
            const stateId = parseInt(stateField.value) || undefined;
            dispatch(addressActions.loadCities(selectedCountryId, stateId));
        }
    }, [dispatch, selectedCountryId, stateField.value]);

    useEffect(() => {
        setFieldValue('isUSASelected', isUSASelected)
    }, [setFieldValue, isUSASelected]);

    const getCityName = (cityId: number, formik: FormikContextType<CloManagerSave>) => {
        const selectedCity = cities.data.find(c => c.id === cityId);

        return selectedCity ? selectedCity.name : formik.getFieldMeta('newCity').value || '';
    }

    const citiesList = useMemo(() => cities.data.map(({ name }) => name), [cities.data]);

    return (
        <SectionContent title="Contact Information" className="data-item-general-cols">
            <div className="content-row">
                <div className="content-col">
                    <TextField label="Website" name="webSite" />
                    <TextField label="LinkedIn" name="linkedIn" />
                    <TextField label="Phone" name="phone" />
                    <SelectField
                        label="Country"
                        name="countryId"
                        values={countries.data}
                        placeholder="Select Country"
                        optionKeyProp="id"
                        optionLabelProp="name"
                        onSelect={formik => {
                            formik
                                .getFieldHelpers('stateId')
                                .setValue(null);
                            formik
                                .getFieldHelpers('cityId')
                                .setValue(null);
                            formik
                                .getFieldHelpers('newCity')
                                .setValue(null);
                        }}
                        required
                        className="country-field"
                    />
                </div>
                <div className="content-col">
                    {isUSASelected && (
                        <SelectField
                            label="State"
                            name="stateId"
                            values={states.data}
                            placeholder="Select State"
                            optionKeyProp="id"
                            optionLabelProp="name"
                            onSelect={formik => {
                                formik
                                    .getFieldHelpers('cityId')
                                    .setValue(null);

                                formik
                                    .getFieldHelpers('newCity')
                                    .setValue(null);
                            }}
                            required
                        />
                    )}
                    <InputField
                        label="City"
                        name="cityId"
                        renderElement={(formik, field, meta) => (
                            <div className="data-item-col">
                                <AutoCompleteInput
                                    className="form-control form-control-search"
                                    isInvalid={((meta.touched && meta.error) || (getIn(formik.touched, 'newCity') && getIn(formik.errors, 'newCity')))}
                                    maxLength={256}
                                    sourceItems={citiesList}
                                    value={getCityName(field.value, formik)}
                                    onChange={(value: any) => {
                                        const selectedCity = cities.data.find(
                                            c => c.name.localeCompare(value, undefined, { sensitivity: 'accent' }) === 0,
                                        );

                                        const cityId = formik.getFieldHelpers('cityId');
                                        const cityName = formik.getFieldHelpers('newCity');

                                        if (selectedCity) {
                                            cityId.setValue(selectedCity.id);
                                            cityName.setValue(null);
                                        } else {
                                            cityId.setValue(null);
                                            cityName.setValue(value);
                                        }
                                    }}
                                    placeholder="City"
                                />
                                {((meta.touched && meta.error) ||
                                    (getIn(formik.touched, 'newCity') && getIn(formik.errors, 'newCity'))) && (
                                    <div className="form-error">{meta.error || getIn(formik.errors, 'newCity')}</div>
                                )}
                            </div>
                        )}
                        required
                    />
                    <TextField label="Zip/Postal Code" name="zipCode" required />
                    <TextField label="Address" name="address" required />
                </div>
            </div>
        </SectionContent>
    );
};
